import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { encode } from 'js-base64';
import LandingFormAppel from "../../components/contacts/landingFormAppel"


const encryptedMail= encode("contact@blicko.fr");
const moyen = "Formulaire blicko Landing RS Appel";

export default function Section1() {
  return (
    <div className="relative bg-white overflow-hidden">
      <main className="lg:mx-auto lg:max-w-7xl lg:pb-16 lg:relative">
        <svg
            className="z-10 hidden lg:block absolute inset-y-0 h-full w-48 text-white transform right-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 100,100 95,100" />
        </svg>
        <div className="hidden mx-auto lg:w-160 text-right lg:text-left lg:block object-cover lg:max-h-80 lg:min-h-80">
          <StaticImage
              className="mx-auto inset-0 w-600 h-full object-cover"
              src="../../images/landing/landing_plombier.jpg"
              alt="Equipe blicko"
            />
        </div>
        <div className="bg-white relative w-full sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
            <div className="m-4 pt-4 lg:pt-4 sm:text-center md:max-w-2xl lg:ml-8 lg:col-span-6 lg:text-left lg:w-120">
              <h2>
                <span className="mt-1 block text-3xl tracking-tight font-extrabold sm:text-3xl xl:text-4xl">
                  <span className="block text-gray-900">Votre plombier <br />de proximité Rhône-Alpes</span>
                  <span className="block text-[#3333e6]">RDV rapide.</span>
                </span>
              </h2>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
              Prenez rendez-vous au <a href="tel:0428296253"><span className="text-[#3333e6] font-bold">04 28 29 62 53</span></a> pour être dépanné avec un de nos plombiers blicko proche de chez vous.
              </p>
              <div className="relative mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0">
                <LandingFormAppel moyen={moyen} destinataire={encryptedMail} />
                <p className="mt-3 text-sm text-gray-500">
                  Ou appel direct au 04 28 29 62 53 (gratuit et sans engagement)
                </p>
              </div>
            </div>
        </div>
      </main>
      
      
      
    </div>
  )
}



